<template>
  <b-overlay
    :show="loading"
    rounded="lg"
    :opacity="0.8"
  >
    <div class="p-2 card">
      <h1>Busca Avançada de Empresas</h1>
      <p>
        Para realizar uma busca, selecione o(s) filtro(s) necessário(s) e clique
        no botão buscar:
      </p>
      <section class="filtros mt-2">
        <validation-observer ref="validacaoFiltros">
          <b-form>
            <b-row>
              <b-col
                xl="3"
                md="6"
              >
                <b-form-group
                  label="Razão Social"
                  label-for="razao"
                >
                  <b-form-input
                    id="razao"
                    v-model="form.razao"
                  />
                </b-form-group>
              </b-col>
              <b-col
                xl="3"
                md="6"
              >
                <b-form-group
                  label="Nome Fantasia"
                  label-for="nomeFantasia"
                >
                  <b-form-input
                    id="nomeFantasia"
                    v-model="form.nomeFantasia"
                  />
                </b-form-group>
              </b-col>
              <b-col
                xl="3"
                md="6"
              >
                <b-form-group
                  label="CNPJ"
                  label-for="campo-cnpj"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="regex:^\d{2}\d{3}\d{3}\d{4}\d{2}$"
                    name="regex"
                    placeholder="cnpj"
                  >
                    <cleave
                      id="campo-cnpj"
                      v-model="form.cnpj"
                      class="form-control"
                      :options="masks.cnpj"
                    />
                    <small class="text-danger">{{
                      errors[0] ? "O CNPJ precisa estar completo" : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                xl="3"
                md="6"
              >
                <b-form-group
                  label="Regionais SESI"
                  label-for="regionais"
                >
                  <v-select
                    id="regionais"
                    v-model="form.regioes"
                    :options="regioesSelect"
                    variant="custom"
                    item-text="descricao"
                    item-value="id_regiao"
                    label="descricao"
                    placeholder="Selecione uma ou mais"
                    :multiple="true"
                    multiselect
                  />
                </b-form-group>
              </b-col>
              <b-col
                xl="3"
                md="6"
              >
                <b-form-group
                  label="Serviços Contratados"
                  label-for="regionais"
                >
                  <v-select
                    id="modulos"
                    v-model="form.modulos"
                    :options="modulosSelect"
                    variant="custom"
                    item-text="descricao"
                    item-value="id_modulo"
                    label="descricao"
                    placeholder="Selecione um ou mais"
                    :multiple="true"
                    multiselect
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="w-100 d-flex justify-content-center my-2">
              <b-button
                class="mr-2"
                type="reset"
                variant="outline-secondary"
                @click="limparFiltros"
              >Limpar</b-button>
              <b-button
                ref="filtrosEmpresa"
                type="submit"
                variant="custom"
                @click.prevent="validaFiltros"
              >Buscar</b-button>
            </div>
          </b-form>
        </validation-observer>
      </section>

      <div
        v-if="carregandoTabela"
        class="
        w-100
        mt-5
        d-flex
        flex-column
        align-items-center
        justify-content-center
      "
      >
        <b-spinner
          variant="custom"
          class="mb-1"
          label="Loading..."
        />
        <span>carregando...</span>
      </div>

      <b-alert
        variant="primary"
        :show="tabela.semDados"
        class="mt-5"
      >
        <div class="alert-body d-flex justify-content-center">
          <span class="text-primary">
            <strong class="text-primary">Nenhum registro encontrado.</strong>
            Realize novamente uma busca utilizando diferentes critérios.
          </span>
        </div>
      </b-alert>

      <b-alert
        variant="primary"
        :show="tabela.erroTabela"
        class="mt-5"
      >
        <div class="alert-body d-flex justify-content-center">
          <span class="text-primary">
            <strong
              class="text-primary"
            >Sistema de busca indisponível no momento.</strong>
          </span>
        </div>
      </b-alert>

      <section
        v-if="mostrarTabela"
        class="tabela-empresas"
      >
        <b-row class="my-2">
          <b-col sm="2">
            <div class="d-flex flex-row align-items-center">
              <label class="mr-50">Visualizar</label>
              <b-form-select
                id="amounlinesVisualizer"
                v-model="dadosPaginacao.quantidadeLinhasPadrao"
                class="w-50"
                size="sm"
                label="option"
                :options="tabela.quantidadeLinhasVisualizar"
                @input="atualizaQuantidadeVisualizar($event)"
              />
            </div>
          </b-col>
          <b-col
            sm="12"
            class="my-2"
          >
            <b-overlay
              :show="tabela.tabelaOcupada"
              rounded="lg"
              opacity="1"
            >
              <b-table
                id="listCompaniesTable"
                responsive
                sticky-header="100%"
                :busy.sync="tabela.tabelaOcupada"
                :no-local-sorting="true"
                :fields="tabela.fields"
                :items="tabela.items"
                @context-changed="ordenarTabela"
              >
                <template #cell(qtd_filiais)="row">
                  <feather-icon
                    v-if="row.value > 0"
                    icon="DiscIcon"
                    size="14"
                    class="pointer text-primary"
                    @click="
                      buscarDadosFiliais(row.item);
                      row.toggleDetails();
                    "
                  />
                  <feather-icon
                    v-if="
                      row.value == 0 &&
                        row.item.tipo_empresa.descricao != 'Filial'
                    "
                    icon="CircleIcon"
                    size="14"
                  />

                  <img
                    v-if="row.item.tipo_empresa.descricao == 'Filial'"
                    src="@/assets/custom-icons/dot.svg"
                    alt="icone de filial"
                  >
                </template>
                <template #cell(nome_fantasia)="row">
                  <strong
                    v-if="row.item.qtd_filiais > 0"
                    class="pointer"
                    @click="
                      buscarDadosFiliais(row.item);
                      row.toggleDetails();
                    "
                  >
                    {{ row.value }}
                  </strong>
                  <strong v-if="row.item.qtd_filiais == 0">{{
                    row.value
                  }}</strong>
                </template>

                <template #cell(cnpj)="row">
                  <span>{{ row.value | VMask("##.###.###/####-##") }}</span>
                </template>

                <template #row-details="row">
                  <div
                    v-if="!row.item.filiais"
                    class="
                    w-100
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                  "
                  >
                    <b-spinner
                      variant="custom"
                      class="mb-1"
                      label="Loading..."
                    />
                  </div>
                  <b-table-simple
                    v-if="row.item.filiais"
                    hover
                    responsive
                    style="background-color: #f8f8f8"
                  >
                    <b-tbody>
                      <b-tr
                        v-for="filial in row.item.filiais"
                        :key="filial.id_empresa"
                      >
                        <b-td class="width-custom-column">
                          <img
                            src="@/assets/custom-icons/dot.svg"
                            alt="icone de filial"
                          >
                        </b-td>
                        <b-td
                          class="width-column-detail-razao"
                        ><strong>{{ filial.nome_fantasia }}</strong></b-td>
                        <b-td class="width-column-detail-tipo">
                          Filial
                        </b-td>
                        <b-td class="width-column-detail-cnpj">{{
                          filial.cnpj | VMask("##.###.###/####-##")
                        }}</b-td>
                        <b-td class="width-column-detail-city">{{
                          filial.cidade.nome
                        }}</b-td>
                        <b-td class="width-column-detail-state">{{
                          filial.uf
                        }}</b-td>
                        <b-td class="width-column-detail-state">
                          <b-form-checkbox
                            :ref="`check-status-${filial.id_empresa}`"
                            v-model="filial.ativo"
                            name="check-button"
                            class="custom-control-success"
                            switch
                            inline
                            @change="mostraModal(filial)"
                          />
                        </b-td>
                        <b-td class="width-column-detail-person">{{
                          filial.qtd_colaboradores
                        }}</b-td>
                        <b-td class="width-column-detail-action">
                          <b-button
                            variant="outline-primary"
                            class="btn-icon"
                            @click="
                              redirecionarParaFilial({
                                id_empresa: filial.id_empresa,
                                id_empresa_grupo: filial.id_empresa_grupo,
                              })
                            "
                          >
                            <feather-icon icon="EditIcon" />
                          </b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </template>
                <template #cell(status)="row">
                  <b-form-checkbox
                    :ref="`check-status-${row.item.id_empresa}`"
                    v-model="row.item.ativo"
                    name="check-button"
                    class="custom-control-success"
                    switch
                    inline
                    @change="mostraModal(row.item)"
                  />
                </template>

                <template #cell(acoes)="row">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon"
                    @click="redirecionarParaMatrizOuFilial(row.item)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </b-col>
          <b-col sm="12">
            <CustomPagination
              :paginacao="dadosPaginacao"
              @page-cliked="atualizarPaginaAtual"
            />
          </b-col>
        </b-row>
      </section>
    </div>
  </b-overlay>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BSpinner,
  BFormSelect,
  BAlert,
  BFormCheckbox,
  BCardText,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { regex } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import 'animate.css'
import { modalGenericModel } from '@/libs/sweetalerts'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    vSelect,
    BButton,
    BFormCheckbox,
    Cleave,
    BTable,
    CustomPagination,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BOverlay,
  },

  data() {
    return {
      loading: true,
      regex,
      permiteAtualizarAtivo: false,
      empresaSelecionada: {
        idEmpresa: null,
        ativo: false,
      },
      modal: {
        tipo: 'ativar'
      },
      form: {
        cnpj: '',
        razao: '',
        nomeFantasia: '',
        modulos: [],
        regioes: [],
      },

      regioesSelect: [],
      modulosSelect: [],

      mostrarTabela: false,
      carregandoTabela: false,
      masks: {
        cnpj: {
          numericOnly: true,
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
        },
      },
      dadosPaginacao: {
        paginaAtual: 1,
        totalLinhas: 1,
        daLinha: 1,
        ateALinha: 1,
        quantidadeLinhasPadrao: 10,
      },
      tabela: {
        semDados: false,
        erroTabela: false,
        tabelaOcupada: false,
        quantidadeLinhasVisualizar: [10, 25, 50, 100],
        ordem: '',
        campoOrdenado: '',
        fields: [
          { key: 'qtd_filiais', label: '', class: 'width-custom-column' },
          { key: 'nome_fantasia', label: 'Nome Fantasia', sortable: true },
          { key: 'tipo_empresa.descricao', label: 'tipo', sortable: true },
          { key: 'cnpj', label: 'cnpj' },
          { key: 'cidade.nome', label: 'cidade', sortable: true },
          { key: 'cidade.uf', label: 'estado', sortable: true },
          { key: 'status', label: 'status', class: 'width-custom-column' },
          { key: 'qtd_colaboradores', label: 'pessoas', sortable: true },
          { key: 'acoes', label: 'ações', class: 'width-custom-column' },
        ],
        items: [],
      },
    }
  },

  mounted() {
    this.popularSelects()
  },

  methods: {
    validaFiltros() {
      this.$refs.validacaoFiltros.validate().then(success => {
        if (success) {
          this.buscarDadosEmpresa(true)
        }
      })
    },

    async popularSelects() {
      this.loading = true

      await this.retornaRegioes()
      await this.retornaModulos()

      this.loading = false
    },

    async retornaRegioes() {
      await this.$http.get(this.$api.RegionaisBusca).then(res => {
        this.regioesSelect = res.data
      })
    },

    async retornaModulos() {
      await this.$http.get(this.$api.ModulosBusca).then(res => {
        this.modulosSelect = res.data
      })
    },

    buscarDadosEmpresa(firstTime = null) {
      if (firstTime && !this.mostrarTabela) {
        this.carregandoTabela = true
      }

      this.tabela.erroTabela = false

      this.tabela.semDados = false
      this.tabela.tabelaOcupada = true

      this.$http
        .get(this.$api.EmpresasBusca, {
          params: this.lidarComParametrosBuscaEmpresa(),
        })
        .then(empresas => {
          if (empresas.data.data.length > 0) {
            this.mostrarTabela = true
            this.tabela.items = empresas.data.data
            this.tabela.tabelaOcupada = false
            this.carregandoTabela = false
            this.IniciarPaginacao(empresas.data)
            return
          }

          this.carregandoTabela = false
          this.tabela.semDados = true
          this.mostrarTabela = false
        })
        .catch(() => {
          this.carregandoTabela = false
          this.tabela.erroTabela = true
        })
    },

    buscarDadosFiliais({ id_empresa, id_empresa_grupo }) {
      this.tabela.erroTabela = false

      const empresaTabela = this.tabela.items.find(
        empresa => empresa.id_empresa === id_empresa,
      )

      if (!empresaTabela.filiais) {
        this.$http
          .get(this.$api.EmpresaBuscaFiliais(id_empresa_grupo))
          .then(filiais => {
            empresaTabela.filiais = filiais.data
            this.$root.$emit('bv::refresh::table', 'listCompaniesTable')
          })
          .catch(() => {
            this.tabela.erroTabela = true
          })
      }
    },
    async ativarInativarEmpresa(empresa) {
      if (!empresa) {
        await this.modalWarningGeneric(
          'Não foi possível realizar a sua solicitação.<br> Entre em contato com o suporte.'
        )

        await this.atualizarPaginaAtual(this.dadosPaginacao.paginaAtual)

        return false
      }

      this.loading = true

      await this.$http
        .put(this.$api.ativarInativarEmpresa(empresa.id_empresa))
        .then(response => {
          if (response.status === 200) {
            this.atualizarPaginaAtual(this.dadosPaginacao.paginaAtual)
          }
        })
        .catch(() => {
          this.modalWarningGeneric(
            'Não foi possível realizar a sua solicitação.<br> Entre em contato com o suporte.'
          )
        })

      this.loading = false
    },

    mostraModal(value) {
      this.modal.tipo = value.ativo ? 'ativar' : 'inativar'
      if (this.modal.tipo == 'ativar') {
        this.modal = {
          texto:
            'Ao ativar uma empresa, será possível cadastrar ou editar filiais, </br>'
            + 'pessoas, perfis e parâmetros, assim como utilizar as </br>'
            + 'funcionalidades dos produtos vinculados a ela.',
          cor: '#36BA00',
          textoBotao: 'Sim, ativar',
          titulo: `Tem certeza que deseja ${this.modal.tipo}?`,
        }
      } else {
        this.modal = {
          texto:
            'Ao inativar uma empresa, não será possível cadastrar ou editar <br>'
            + ' filiais, pessoas, perfis e parâmetros, ou utilizar as '
            + ' funcionalidades </br>dos produtos. Os dados existentes não serão'
            + " perdidos.</br><strong> <div style='color:red'>Atenção: Todos os ciclos desta empresa que estão em Andamento ou Agendados serão cancelados!</div></strong>",
          cor: '#FF7C02',
          textoBotao: 'Sim, inativar',
          titulo: `Tem certeza que deseja ${this.modal.tipo}?`,
        }
      }

      modalGenericModel(
        this.modal.titulo,
        this.modal.texto,
        this.modal.tipo === 'ativar' ? require('@/assets/custom-icons/cora-icons/warning.png')
        : require('@/assets/custom-icons/cora-icons/warning-red-icon.png'),
        this.modal.textoBotao,
        'Cancelar'
      ).then(result => {
        if (result.value) {
          this.ativarInativarEmpresa(value)
        } else {
          this.atualizarPaginaAtual(this.dadosPaginacao.paginaAtual)
        }
      })
    },

    redirecionarParaMatrizOuFilial(empresa) {
      let rota = ''

      if (empresa.id_tipo_empresa === 1) {
        rota = `/grupo/${empresa.id_empresa_grupo}/matriz`
      }

      if (empresa.id_tipo_empresa === 2) {
        rota = `/grupo/${empresa.id_empresa_grupo_filial}/filiais/${empresa.id_empresa}`

        this.$store.commit('modal/exibirModalAtualizarFilial', {
          empresa,
          tituloModal: `Editar - Filial: ${empresa.razao}`,
          tipoEmpresa: 2,
          mostrarModal: true,
          editarFilialPelaBuscaGeral: true,
        })
      }

      this.$router.push({ path: rota })
    },

    redirecionarParaFilial({ id_empresa, id_empresa_grupo }) {
      this.$router.push({
        path: `/grupo/${id_empresa_grupo}/filiais/${id_empresa}`,
      })
    },

    ordenarTabela(contextoTabela) {
      this.tabela.campoOrdenado = contextoTabela.sortBy
      this.tabela.ordem = contextoTabela.sortDesc ? 'desc' : 'asc'

      this.buscarDadosEmpresa()
    },

    lidarComParametrosBuscaEmpresa() {
      return {
        razao: this.form.razao,
        nomeFantasia: this.form.nomeFantasia,
        cnpj: this.form.cnpj,
        id_regiao: this.$helpers.retornaAtributoArray(
          this.form.regioes,
          'id_regiao',
        ),
        id_modulo: this.$helpers.retornaAtributoArray(
          this.form.modulos,
          'id_modulo',
        ),
        columnName: this.tabela.campoOrdenado,
        columnOrder: this.tabela.ordem,
        perPage: this.dadosPaginacao.quantidadeLinhasPadrao,
        page: this.dadosPaginacao.paginaAtual,
      }
    },

    limparFiltros() {
      this.form.razao = ''
      this.form.nomeFantasia = ''
      this.form.cnpj = ''
      this.form.modulos = []
      this.form.regioes = []
    },

    IniciarPaginacao(dadosPaginacao) {
      this.dadosPaginacao.daLinha = dadosPaginacao.from
      this.dadosPaginacao.ateALinha = dadosPaginacao.to
      this.dadosPaginacao.totalLinhas = dadosPaginacao.total
      this.dadosPaginacao.paginaAtual = dadosPaginacao.current_page
      this.dadosPaginacao.quantidadeLinhasPadrao = dadosPaginacao.per_page
    },

    atualizarPaginaAtual(page) {
      this.dadosPaginacao.paginaAtual = page
      this.buscarDadosEmpresa()
    },

    atualizaQuantidadeVisualizar(event) {
      if (!event) {
        this.dadosPaginacao.quantidadeLinhasPadrao = 10
      }

      this.dadosPaginacao.paginaAtual = 1
      this.buscarDadosEmpresa()
    },

    setModulos(moduleIds) {
      this.form.modulos = moduleIds
    },
    setRegional(regiaoIds) {
      this.form.regioes = regiaoIds
    },
    modalWarningGeneric(message){
      modalGenericModel(
        'Alerta!',
        message,
        require('@/assets/custom-icons/cora-icons/warning.png'),
        'Ok'
      )
    }
  },
}
</script>
